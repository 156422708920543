$(document).ready(function(){
    $('#modal-login').on('show.bs.modal', function (e) {
        var $relatedTarget = $(e.relatedTarget),
            actionNext = $relatedTarget.attr("data-next");
        actionNext = (actionNext == "href")? $relatedTarget.attr("href"):actionNext;

        $("#action-next").val(actionNext);
    });
});

$(window).on("load", function() {
    //Verificando se é para abrir algum modal
    var x = location.hash;
    if (x) {
        if (strstr(x, "#modal-curso-") !== false) {
            var course = x.substr(13);
            $("a.insc-curso[data-course="+course+"]").click();
        }else if (x === "#modal-payment") {
            $("#modal-payment").modal({show:true});
        }
    }

    //Mudar view Login e Lembrar Senha
    $(".login-remenber").on("click", function () {
        $(this).closest(".modal-dialog").find(".modal-content").toggleClass("d-none");
    });

    //Marcar/Desmarcar a opção alterar senha no perfil do usuário
    $("#change-pass").on('change', function(e) {
        var changePass = $(this).prop("checked");

        $(this).parent().toggleClass("mb-5").toggleClass("mb-3").next().slideToggle();
        $("#password").val("").prop("required", changePass);
        $("#cpassword").val("").prop("required", changePass);
    });

    
});